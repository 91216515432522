import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useContext } from "react";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import { LoaderContainer } from "./styles";

export default function Loader() {
  const { loading } = useContext(GeneralContext);

  return (
    <LoaderContainer style={{ opacity: loading ? 1 : 0 }}>
      <LinearProgress />
    </LoaderContainer>
  );
}
