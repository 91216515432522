import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Button from "../../atoms/Button";
import {
  NameContainer,
  NameHolder,
  NameItem,
  NameList,
  NameListHolder,
} from "./styles";

function NamePicker({ setQuery, query }) {
  const [users, setUsers] = useState([]);

  const [checked, setChecked] = useState(false);


  useEffect(() => {
    api("report/users", {
      method: "GET",
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setUsers(data.response);
      }
    });
  }, []);

  const handle_check = () => {
    setChecked(true);
  };

  const handle_clickaway = () => {
    setChecked(false);
  };

  const handle_submit = (user_id) => {
    const new_query = `user_id LIKE "${user_id}"`;

    setQuery({ ...query, user: new_query });
  };

  const Users = () => {
    return (
      <>
        {users.map((item, index) => (
          <NameItem
            key={`name_${index}`}
            onClick={() => handle_submit(item.user_id)}
          >
            {item.user_name}
          </NameItem>
        ))}
      </>
    );
  };

  return (
    <ClickAwayListener onClickAway={handle_clickaway}>
      <NameContainer>
        <Button width="150px" margin="0 .5rem 0 .5rem" onClick={handle_check}>
          Selecionar Usuário
        </Button>
        <Grow
          in={checked}
          style={{ transformOrigin: "0 0 0" }}
          {...(checked ? { timeout: 200 } : {})}
        >
          <NameHolder>
            <NameListHolder>
              <NameList>
                <Users />
              </NameList>
            </NameListHolder>
          </NameHolder>
        </Grow>
      </NameContainer>
    </ClickAwayListener>
  );
}

export default NamePicker;
