import styled from "styled-components";

export const KeywordsContainer = styled.div`
  width: 100%;
  min-width: 30px;
  height: 100%;
  overflow-y: scroll;
  color: ${(props) => props.theme.color.text};
`;
export const TableData = styled.td`
  padding: 1rem;
  font-size: 1.2rem;
  span {
    display: inline-block;
    width: 100%;
    white-space: wrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
`;

export const AddDiv = styled.div`
  width: 10%;
`;

export const InformationContainer = styled.div`
  width: 90%;
  margin-left:auto;
  margin-right: auto;
  color: ${(props) => props.theme.color.text};
  height: calc(100vh - 90px);
  margin-top: 110px;
`;

export const TableRow = styled.tr``;
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;
