import styled from "styled-components";

export const PieContainer = styled.div`
  *:before,
  *:after,
  * {
    color: ${(props) => props.theme.color.background};
  }

  .tooltipHeader {
    width: 100%;
    background: #eceff1;
    padding: 0.5rem;
    border-bottom: 1px solid #0000001d;
    font-size: 1.2rem;
  }

  .tooltipBody {
    padding: 1rem;
    font-size: 1.2rem;
  }
`;
