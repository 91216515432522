import { Form } from "@unform/web";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useContext, useRef } from "react";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import api from "../../../services/api";
import Button from "../../atoms/Button";
import ImageInput from "../../molecule/ImageInput";
import Input from "../../atoms/ProfileInput";
import {
  ChangeContainer,
  ChangeHolder,
  InformationContainer,
  InformationSection,
  InformationTitle,
  ProfileInformation,
  ProfilePhotoContainer,
} from "./styles";

function EditUserInformation({ editMode, user }) {
  const formRef = useRef(null);

  const { setLoading } = useContext(GeneralContext);
  const { enqueueSnackbar } = useSnackbar();

  const handle_submit = async (payload) => {
    setLoading(true);

    let formData = new FormData();
    formData.append("display_name", payload.user);
    formData.append("email", payload.email);
    formData.append("photo_url", payload.avatar);

    api("user", {
      method: "PUT",
      body: formData,
    }).then(async (res) => {
      if (res.status >= 200 && res.status < 300) {
        setLoading(false);
        enqueueSnackbar("Alterado com sucesso!", { variant: "success" });
        editMode(false);
      } else {
        setLoading(false);
        enqueueSnackbar("Houve um erro para alterar!", { variant: "error" });
      }
    });
  };

  const submit_form = () => {
    formRef.current.submitForm();
  };

  return (
    <ProfileInformation>
      <Form onSubmit={handle_submit} ref={formRef}>
        <ProfilePhotoContainer>
          <ImageInput name="avatar" image={user.photoURL} />
        </ProfilePhotoContainer>
        <InformationContainer>
          <InformationSection>
            <InformationTitle>NOME DE USUÁRIO:</InformationTitle>
            <Input name="user" defaultValue={user.displayName} />
          </InformationSection>
          <InformationSection>
            <InformationTitle>EMAIL:</InformationTitle>
            <Input name="email" defaultValue={user.email} />
          </InformationSection>
        </InformationContainer>
      </Form>
      <ChangeContainer>
        <ChangeHolder>
          <Button width={"100%"} onClick={submit_form}>
            Editar
          </Button>
        </ChangeHolder>
      </ChangeContainer>
    </ProfileInformation>
  );
}

export default EditUserInformation;
