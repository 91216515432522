import React from "react";
import Aside from "../../components/organism/Aside";
import Loader from "../../components/atoms/Loader";
import GlobalStyle from "../../static/global_css";
import { PanelContainer } from "./styles";

function Panel({ Content }) {
  return (
    <PanelContainer>
      <GlobalStyle />
      <Loader />
      <Aside />
      <Content />
    </PanelContainer>
  );
}

export default Panel;
