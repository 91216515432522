import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext } from "react";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import AddSection from "../../molecule/AddSection";
import Button from "../../atoms/Button";
import EditSection from "../../molecule/EditSection";
import Pagination from "../../atoms/Pagination";
import TableFilter from "../../organism/TableFilter";
import RoundedButton from "../../atoms/TableRoundedButton";
import {
  TableBody,
  TableContainer,
  TableData,
  TableDataContentHolder,
  TableHead,
  TableHeader,
  TableObject,
  TableRow,
  TableSettings,
  TableSettingsActions,
} from "./styles";

function KeywordTable({
  tableHeader,
  tableRows,
  page,
  setPage,
  totalPages,
  setRefresh,
  addPage,
  edit,
  handle_delete,
  setQuery,
}) {
  const { user } = useContext(GeneralContext);

  return (
    <TableContainer>
      <TableSettings>
        <TableSettingsActions>
          {addPage == "keywords" ? <>
          <TableFilter setQuery={setQuery} />
          <Button
            width="150px"
            margin="0 .5rem 0 .5rem"
            onClick={() => setRefresh(Math.random())}
          >
            Limpar Filtro
          </Button>
          </> : null}
          {user.role !== "viewer" ? (
            <AddSection setRefresh={setRefresh} addPage={addPage} />
          ) : null}
          {addPage =="keywords" || addPage == "blacklist" ? <Pagination page={page} setPage={setPage} totalPages={totalPages} /> : null}
        </TableSettingsActions>
      </TableSettings>
      <TableObject>
        <TableHeader>
          <TableRow>
            {!!tableHeader && tableHeader.length > 0
              ? tableHeader.map((item, index) => (
                  <TableHead key={index}>{item}</TableHead>
                ))
              : null}
          </TableRow>
        </TableHeader>
        <TableBody>
          {!!tableRows && tableRows.length > 0
            ? tableRows.map((item, index) => (
                <TableRow key={index}>
                  <TableData>{page * 10 - 10 + index}</TableData>
                  <TableData title={item}>
                    <span>{item}</span>
                  </TableData>
                  {user.role !== "viewer" ? (
                    <TableData>
                      <TableDataContentHolder>
                        <RoundedButton onClick={() => handle_delete(item)}>
                          <DeleteIcon />
                        </RoundedButton>
                        {edit && <EditSection setRefresh={setRefresh} rowData={item} />}
                      </TableDataContentHolder>
                    </TableData>
                  ) : null}
                </TableRow>
              ))
            : null}
        </TableBody>
      </TableObject>
    </TableContainer>
  );
}

export default KeywordTable;
