import React from "react";
import Button from "../../atoms/Button";
import {
  ChangeContainer,
  ChangeHolder,
  InformationContainer,
  InformationSection,
  InformationTitle,
  ProfileInformation,
  ProfilePhoto,
  ProfilePhotoContainer,
  ProfilePhotoHolder,
} from "./styles";

function UserInformation({ editMode, user }) {
  return (
    <ProfileInformation>
      <ProfilePhotoContainer>
        <ProfilePhotoHolder>
          <ProfilePhoto src={user.photoURL} />
        </ProfilePhotoHolder>
      </ProfilePhotoContainer>
      <InformationContainer>
        <InformationSection>
          <InformationTitle>NOME DE USUÁRIO:</InformationTitle>
          <p>{user.displayName}</p>
        </InformationSection>
        <InformationSection>
          <InformationTitle>EMAIL:</InformationTitle>
          <p>{user.email}</p>
        </InformationSection>
      </InformationContainer>
      <ChangeContainer>
        <ChangeHolder>
          <Button width={"100%"} onClick={() => editMode(true)}>
            Editar
          </Button>
        </ChangeHolder>
      </ChangeContainer>
    </ProfileInformation>
  );
}

export default UserInformation;
