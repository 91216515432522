import Cookies from "js-cookie";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/atoms/Header";
import Table from "../../components/organism/ReportTable";
import AddSection from "../../components/molecule/AddSection";
import Card from "../../components/atoms/Card";
import RoundedButton from "../../components/atoms/TableRoundedButton";
import GeneralContext from "../../contexts/GeneralContext/GeneralContext";
//services
import api from "../../services/api";
import { KeywordsContainer,
  ActionsContainer, 
  TableData,
  TableRow,
  AddDiv,
  InformationContainer } from "./styles";

function Keywords() {
  const [tableRows, setTableRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [query, setQuery] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading, user } = useContext(GeneralContext);


  useEffect(() => {
    setLoading(true);

    api(`getUsersInBl?page=${page}`, {
      method: "GET",
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setTableRows(data.ids);
        setTotalPages(data.totalPages);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [page, setLoading, refresh]);

  useEffect(() => {
    let filtered = tableRows.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );

    if (filtered.length === 0 && tableRows.length > 0) {
      api(`search/terms?query=${query}`, {
        method: "GET",
      }).then(async (res) => {
        const data = await res.json();
        if (res.status >= 200 && res.status < 300) {
          setTableRows([data.term]);
        } else {
          enqueueSnackbar(data.response, { variant: "error" });
        }
      });
    } else {
      setTableRows(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, query]);

  const TableRowsEx = () => {
    
    return (
      <>
        {tableRows.length > 0
          ? tableRows.map((item, index) => (
              <TableRow key={index}>
                <TableData>{index+1}</TableData>
                <TableData title={item.uid}>
                  <span>{item.id}</span>
                </TableData>
                <TableData title={item.displayName}>
                  <span>{item.nome}</span>
                </TableData>
                <TableData title={item.email}>
                  <span>{item.email}</span>
                </TableData>
                <TableData>
                  <ActionsContainer>
                    <RoundedButton
                      disabled={
                        user.role === "admin" && item.role === "Proprietário"
                      }
                      onClick={() => handle_delete(item.id)}
                    >
                      <DeleteIcon />
                    </RoundedButton>
                  </ActionsContainer>
                </TableData>
              </TableRow>
            ))
          : null}
      </>
    );
  };

  const handle_delete = async (id) => {
    console.log(id);
    api(`removeUserBl`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        enqueueSnackbar(data.response, { variant: "success" });
        setRefresh(Math.random());
      } else {
        enqueueSnackbar(data.response, { variant: "error" });
      }
    });
  };

  return (
    <KeywordsContainer>
      <Header>
        <strong>DLP</strong>&nbsp;|&nbsp;BlackList
      </Header>
      <InformationContainer>
      <AddDiv>
      {user.role !== "viewer" ? (
            <AddSection setRefresh={setRefresh} addPage={"blacklist"} />
          ) : null}
      </AddDiv>
      <Card width={"100%"} minWidth={"250px"} overflow={"scroll"}>
      <Table
        page={page}
        setPage={setPage}
        results={totalPages}
        tableHeader={[
          "Índice",
          "ID",
          "Nome",
          "Email",
          "Deletar"
        ]}
        TableRows={TableRowsEx}
      />
      
      </Card>
      </InformationContainer>
    </KeywordsContainer>
  );
}

export default Keywords;
