import React from "react";
import { CardContainer } from "./styles";

function Card({ width, children, minWidth, minHeight, overflow }) {
  return (
    <CardContainer
      width={width}
      minWidth={minWidth}
      minHeight={minHeight}
      overflow={overflow}
    >
      {children}
    </CardContainer>
  );
}

export default Card;
