import React, { useContext } from "react";
import Header from "../../components/atoms/Header";
import LockerAnimation from "../../components/atoms/LockerAnimation";
import GeneralContext from "../../contexts/GeneralContext/GeneralContext";
import {
  ContentContainer,
  HomeContainer,
  UserParagraph,
  UserTextHolder,
  WelcomeUser,
} from "./styles";

function Home() {
  const { user } = useContext(GeneralContext);

  return (
    <HomeContainer>
      <Header>
        <strong>DLP</strong>&nbsp;|&nbsp;Página Inicial
      </Header>
      <ContentContainer>
        <WelcomeUser>
          Bem vindo, <strong>{user.displayName || "Novo usuário"}!</strong>
        </WelcomeUser>
        <LockerAnimation />
        <UserTextHolder>
          <UserParagraph>
            A Solução de DLP, desenvolvida pela Alest Consultoria, escaneia os
            dados sensíveis localizados em textos e imagens.
          </UserParagraph>
          <UserParagraph>
            Este é o painel de gerenciamento e visualização de infrações
            capturadas pelo Data Loss Prevent no Workplace.
          </UserParagraph>
          <UserParagraph>
            Neste você é capaz de adicionar ou remover palavras proibidas,
            visualizar métricas de ação do bot e insights sobre infrações para
            otimizar a tomada de decisões acerca de possíveis problemas.
          </UserParagraph>
        </UserTextHolder>
      </ContentContainer>
    </HomeContainer>
  );
}

export default Home;
