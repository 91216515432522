import { Form } from "@unform/web";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useContext, useRef } from "react";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import api from "../../../services/api";
import Button from "../../atoms/Button";
import ImageInput from "../../molecule/ImageInput";
import Input from "../../atoms/ProfileInput";
import Select from "../../molecule/SelectInput";
import {
  ChangeContainer,
  ChangeHolder,
  InformationContainer,
  InformationSection,
  InformationTitle,
  Option,
  ProfileContainer,
  ProfileInformation,
  ProfilePhotoContainer,
} from "./styles";

function AddUser() {
  const { user } = useContext(GeneralContext);

  const formRef = useRef(null);

  const { setLoading } = useContext(GeneralContext);
  const { enqueueSnackbar } = useSnackbar();

  const submit_form = () => {
    formRef.current.submitForm();
  };

  const handle_submit = (payload) => {
    setLoading(true);
    // const regex = /^(?=.*[@!#$%^&*()/\\])[@!#$%^&*()/\\a-zA-Z0-9]{8,20}$/
    console.log(payload.password + ` = ` + payload.confirm_password)
    if (payload.password === payload.confirm_password) {
      let formData = new FormData();
      formData.append("display_name", payload.display_name);
      formData.append("email", payload.email);
      formData.append("password", payload.password);
      formData.append("role", payload.role);
      formData.append("photo_url", payload.photo_url);
      api("user", {
        method: "POST",
        body: formData,
        headers: {
        },
      }).then(async (res) => {
        const data = await res.json();
        if (res.status >= 200 && res.status < 300) {
          enqueueSnackbar(data.response, { variant: "success" });
          setLoading(false);
        } else {
          enqueueSnackbar(data.response, { variant: "error" });
          setLoading(false);
        }
      });
    } else {
      enqueueSnackbar("A senha é diferente da senha de verificação.", {
        variant: "warning",
      });
      setLoading(false);
    }
  };

  return (
    <ProfileContainer>
      <ProfileInformation>
        <Form onSubmit={handle_submit} ref={formRef}>
          <ProfilePhotoContainer>
            <ImageInput name="photo_url" />
          </ProfilePhotoContainer>
          <InformationContainer>
            <InformationSection>
              <InformationTitle>NOME DE USUÁRIO</InformationTitle>
              <Input name="display_name" />
            </InformationSection>
            <InformationSection>
              <InformationTitle>EMAIL</InformationTitle>
              <Input type="email" name="email" />
            </InformationSection>
            <InformationSection>
              <InformationTitle>SENHA</InformationTitle>
              <Input type="password" name="password" />
              <Input type="password" name="confirm_password" />
            </InformationSection>
            <InformationSection>
              <InformationTitle>CARGO</InformationTitle>
              <Select name="role">
                {user.role === "owner" ? (
                  <Option value="owner">Proprietário</Option>
                ) : null}
                <Option value="admin">Administrador</Option>
                <Option value="viewer">Visualizador</Option>
              </Select>
            </InformationSection>
          </InformationContainer>
        </Form>
        <ChangeContainer>
          <ChangeHolder>
            <Button onClick={submit_form}>Adicionar</Button>
          </ChangeHolder>
        </ChangeContainer>
      </ProfileInformation>
    </ProfileContainer>
  );
}

export default AddUser;
