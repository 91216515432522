import styled from "styled-components";

export const ReportContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  color: ${(props) => props.theme.color.text};
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.color.background};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.secondary};
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.color.stroke};
  }
`;

export const ReportHolder = styled.div`
  width: 100%;
  padding: 2%;
`;

export const ChartHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
`;

export const ChartRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  @media (max-width: 705px) {
    flex-wrap: wrap;
  }
`;

export const ReportHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  word-wrap: break-word;
`;

export const TableRow = styled.tr`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableData = styled.td`
  padding: 1.2rem;
  font-size: 1.3em;
  span {
    padding: 1rem;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CardTitle = styled.div`
  padding: 0.5rem;
  font-size: 1.3rem;
`;

export const CardContent = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  transition: transform 200ms ease;

  :hover {
    transform: translateY(-10%);
    cursor: default;
  }
`;
