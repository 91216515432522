import IconButton from "@material-ui/core/IconButton";
import { useLocation } from 'react-router-dom';
import React, { useState, useContext } from "react";
import {
  LeftArrowIcon,
  RightArrowIcon,
  TableBody,
  TableContainer,
  TableHead,
  TableHeader,
  TableObject,
  TableRow,
  TableSettings,
  TableConfig,
  ExportCSV,
  DataFilter,
  ButtonCSV
} from "./styles";
import api from "../../../services/api";
import { saveAs } from "file-saver";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import ThemeContext from "../../../contexts/ThemeContext/ThemeContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ReportTable({ tableHeader, TableRows, page, setPage, results }) {
  const { setLoading } = useContext(GeneralContext);
  const { theme } = useContext(ThemeContext);

  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');

  const downloadBlCSV = async () => {
    setLoading(true);

    api(`getAllUsersBl`, {
      method: "GET",
    }).then(async (res) => {
        setLoading(false)
        const data = await res.json();
        try {
          const csvString = [
            [
              "id",
              "email",
              "nome"
            ],
            ...data.users.map(item => [
              item.id,
              item.email,
              item.nome
            ])
          ].map(e => e.join(",")).join("\n");
          const blob = new Blob(["", csvString], {
            type: "text/csv;charset=utf-8",
          });
          saveAs(blob, `csv_dados_BL.csv`);
        } catch (error) {
          toast.error(`Algo deu errado! ${error}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme.color.background === "#2A2D30" ? "dark" : "light",
          });
          return;
        }
      });
    } 
  

  const downloadCSV = async () => {
    if (finalDate < initialDate) {
      toast.error('A data de início não deve ser maior que a data de fim!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme.color.background === "#2A2D30" ? "dark" : "light",
      });
      return;
    }
    if (initialDate !== '' && finalDate !== '') {
      setLoading(true);
      const query = `DATE(created_time) BETWEEN DATE('${initialDate} 00:00:00 UTC') AND DATE('${finalDate} 23:59:59 UTC')`;

      api(`report/contentCSV`, {
        method: "GET",
        headers: {
          Query: query,
        },
      }).then(async (res) => {
        setLoading(false)
        const data = await res.json();
        if (res.status >= 200 && res.status < 300) {
          const res = data.response;
          const csvString = [
            [
              "created_time",
              "user_name",
              "content",
              "file_type",
              "violation_type",
              "violation_quote",
              "local"
            ],
            ...res.map(item => [
              item.created_time.value,
              item.user_name,
              item.content,
              item.file_type,
              item.violation_type,
              item.violation_quote,
              item.local
            ])
          ].map(e => e.join(",")).join("\n");
          const blob = new Blob(["", csvString], {
            type: "text/csv;charset=utf-8",
          });
          saveAs(blob, `csv_dados_DLP.csv`);
        } else {
          toast.error('Algo deu errado!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme.color.background === "#2A2D30" ? "dark" : "light",
          });
          return;
        }
      });
    } else {
      toast.error('Preencha corretamente os campos de data!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme.color.background === `#2A2D30` ? "dark" : "light",
      });
      return;
    }
  }
  let location = useLocation();
  let path = location.pathname;

  return (
    <TableContainer>
      <ToastContainer />
      <TableConfig>
        <TableSettings>
          <IconButton onClick={() => setPage(page > 1 ? page - 1 : page)}>
            <LeftArrowIcon />
          </IconButton>
          {page}
          <IconButton
            onClick={() =>
              setPage(page + 1 <= Math.ceil(results) ? page + 1 : page)
            }
          >
            <RightArrowIcon />
          </IconButton>
          Páginas: {results}
        </TableSettings>
        {
          path === "/report" &&
          <ExportCSV>
            <DataFilter onChange={(e) => setInitialDate(e.target.value)} />
            <DataFilter onChange={(e) => setFinalDate(e.target.value)} />
            <ButtonCSV onClick={downloadCSV}>Exportar CSV</ButtonCSV>
          </ExportCSV>
        }
        {
          path === "/blacklist" &&
          <ExportCSV>
            <ButtonCSV onClick={downloadBlCSV}>Exportar CSV</ButtonCSV>
          </ExportCSV>
        }
      </TableConfig>
      <TableObject>
        <TableHeader>
          <TableRow>
            {!!tableHeader && tableHeader.length > 0
              ? tableHeader.map((item, index) => (
                <TableHead key={index}>{item}</TableHead>
              ))
              : null}
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRows />
        </TableBody>
      </TableObject>
    </TableContainer>
  );
}

export default ReportTable;
