import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AlestLogo from "../../components/atoms/Alest";
import Loader from "../../components/atoms/Loader";
import LoginBox from "../../components/organism/LoginBox";
import AuthContext from "../../contexts/AuthContext/AuthContext";
import GlobalStyle from "../../static/global_css";
//styles
import { Background, LoginContainer, Logo, MiddleContainer } from "./styles";

function Login() {
  const { auth, setAuth } = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    if (auth === true) history.push("/home");
  }, [auth, history]);

  if (Cookies.get("@user") === 'login') {
    setAuth(true);
  }

  const go_to_alest = () => {
    return window.open("https://www.alest.com.br/", "_blank");
  };

  return (
    <LoginContainer>
      <GlobalStyle />
      <Loader />
      <Background />
      <Logo onClick={go_to_alest}>
        <AlestLogo />
      </Logo>
      <MiddleContainer>
        <LoginBox />
      </MiddleContainer>
    </LoginContainer>
  );
}

export default Login;
