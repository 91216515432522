import React, { useEffect, useRef, useState, useCallback } from 'react';
import defaultView from '../../../static/Images/shino.jpg';

import { useField } from '@unform/core';

import { InputContainer, PhotoPreviewHolder, PhotoPreview } from './styles';

export default function ImageInput({ name, image, ...rest }) {

  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  const [ preview, setPreview ] = useState(image || defaultView);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      setValue: (value) => {
        setPreview(value);
      }
    });
  }, [fieldName, registerField]);

  const handlePreview = useCallback((e) => {
    const file = e.target.files[0];
    
    if(!file){
      return;
    }

    const previewURL = URL.createObjectURL(file);

    setPreview(previewURL);

  }, []);

  return(
    <InputContainer>
      <PhotoPreviewHolder htmlFor='image'>
        <PhotoPreview src={preview}/>
      </PhotoPreviewHolder>
      <input 
        id='image' 
        style={{ display: 'none' }} 
        onChange={handlePreview} 
        ref={inputRef} 
        defaultValue={defaultValue} 
        type='file'
        alt='image_here' {...rest} 
      />
    </InputContainer>
  );
}