import styled from "styled-components";

export const ProfileContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
`;

export const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
`;

export const ProfileInformation = styled.div`
  width: 100%;
  max-width: 50rem;
  background: ${(props) => props.theme.color.background};
  padding: 10px 20px;
  border-radius: 5px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
  }
`;

export const ProfilePhotoHolder = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.color.stroke};
`;

export const ProfilePhotoContainer = styled.div`
  min-height: 100%;
  width: 15%;
  margin-right: 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const ProfilePhoto = styled.img`
  width: 100%;
`;

export const InformationContainer = styled.div`
  width: 85%;
`;

export const InformationTitle = styled.h2`
  color: ${(props) => props.theme.color.text};
  font-size: inherit;
  margin-bottom: 0.5rem;
`;

export const InformationSection = styled.div`
  margin: 2rem 0;
`;

export const ChangeContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.color.text}1d;
`;

export const ChangeHolder = styled.div`
  display: flex;
  justify-content: center;
`;

export const Option = styled.option`
  justify-content: center;
`;
