import styled from "styled-components";

export const GrowContainer = styled.div``;

export const AddContainer = styled.div`
  position: relative;
`;

export const AddHolder = styled.div`
  width: 200%;
  height: 33rem;
  background: ${(props) => props.theme.usermenu.background};
  box-shadow: 0 2px 5px #0000001d;
  margin-top: 1rem;
  margin-right: -70%;
  z-index: 100;
  right: 10%;
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  @media (max-width: 415px) {
    width: 160%;
  }
`;

export const AddSectionTitle = styled.h2`
  font-weight: 400;
  font-size: 1.8rem;
`;

export const AddSectionHeader = styled.div`
  margin-bottom: 1rem;
`;

export const AddBoxButtonsHolder = styled.div`
  display: flex;
  justify-content: center;
`;
