import React, { useContext } from "react";
import { useMenuState } from "reakit/Menu";
import Logout from "../../molecule/Logout";
import SwitchButton from "../../atoms/Switch";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import ThemeContext from "../../../contexts/ThemeContext/ThemeContext";
import * as Themes from "../../../static/themes";
import {
  Description,
  Image,
  LightIcon,
  PhotoHolder,
  Text,
  UserHolder,
  UserMenu,
  UserMenuButton,
  UserMenuItem,
} from "./styles";

function UserMenuPopover({ children, width, margin, ...rest }) {
  const menu = useMenuState({ placement: "right-end" });
  const { user } = useContext(GeneralContext);
  const { theme, toggleTheme } = useContext(ThemeContext);

  const checkedTheme = () => {
    if (theme === Themes.dark) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <UserMenuButton {...menu}>
        <UserHolder>
          <PhotoHolder>
            <Image src={user.photoURL} />
          </PhotoHolder>
          <Text className="AsideText">{user.displayName}</Text>
        </UserHolder>
      </UserMenuButton>
      <UserMenu {...menu} aria-label="Preferences" placement="bottom-end">
        <UserMenuItem {...menu}>
          <Description>
            <LightIcon />
            Mudar Tema
          </Description>
          <SwitchButton onChange={toggleTheme} checked={checkedTheme()} />
        </UserMenuItem>
        <UserMenuItem {...menu}>
          <Description>
            <Logout>sair</Logout>
          </Description>
        </UserMenuItem>
      </UserMenu>
    </div>
  );
}

export default UserMenuPopover;
