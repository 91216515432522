import React, { useContext } from "react";
import Chart from "react-apexcharts";
import ThemeContext from "../../../contexts/ThemeContext/ThemeContext";
import { BubbleContainer } from "./styles";

function BubbleChart({ data, name }) {
  const { theme } = useContext(ThemeContext);

  return (
    <BubbleContainer>
      <Chart
        options={{
          chart: {
            type: "bubble",
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 0.8,
          },
          title: {
            align: "left",
            style: {
              fontFamily: "Roboto",
              color: theme.color.text,
            },
          },
          legend: {
            show: false,
          },
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class="tooltipContainer">
                                    <div class="tooltipHeader">${w.globals.seriesNames[seriesIndex]}</div>
                                    <div class="tooltipBody">
                                        Pessoas alcançadas: <strong>${w.globals.seriesX[seriesIndex][0]}</strong><br/>
                                        Quantidade de infrações: <strong>${series[seriesIndex]}</strong>
                                    </div>
                                </div>`;
            },
          },
          xaxis: {
            tickAmount: 2,
            type: "numeric",
            labels: {
              style: {
                colors: theme.color.text,
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: theme.color.text,
              },
            },
          },
          grid: {
            borderColor: "#e0e0e01d",
          },
        }}
        series={data}
        type="bubble"
      />
    </BubbleContainer>
  );
}

export default BubbleChart;
