import { SafeFill } from "@styled-icons/bootstrap/SafeFill";
import { Form as Unform } from "@unform/web";
import styled from "styled-components";

export const SafeIcon = styled(SafeFill)`
  color: ${(props) => props.theme.color.secondary};
  width: 7vmin;
  margin-bottom: 2vh;
  margin-right: 0.8vw;
`;

export const ProductNameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 20%;
  min-width: 140px;
`;

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.login.background};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

export const LoginTitle = styled.div`
  width: 100%;
  font-size: 3.7vmin;
  font-weight: bold;
  color: ${(props) => props.theme.login.text};
  text-align: center;
  margin-bottom: 2vh;
  margin-top: 7vh;
`;
export const ProductName = styled.div`
  font-size: 4vmin;
  font-weight: bold;
  text-align: flex;
  justify-content: center;
  margin-bottom: 2vh;
  align-items: center;
  line-height: 80%;
  color: ${(props) => props.theme.color.secondary};
`;
export const Divider = styled.hr`
  align-self: center;
  width: 60%;
  border-top: 1px solid ${(props) => props.theme.login.stroke};
`;
