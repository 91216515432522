import styled from "styled-components";

export const InputContainer = styled.div`
  input {
    background: ${(props) => props.theme.color.background};
    border-radius: 30px;
    padding: 5px 10px;
    border: 1px solid #3333331d;
    position: relative;
    z-index: 1;
    left: 35px;
    width: 0px;
    transition: 200ms ease-in;
  }
`;

export const PhotoPreviewHolder = styled.label`
  width: 10rem;
  display: block;
  height: 10rem;
  border-radius: 50%;
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.color.stroke};
  cursor: pointer;
`;

export const PhotoPreview = styled.img`
  width: 100%;
  height: 100%;
`;
