import { Menu } from "@styled-icons/boxicons-regular/Menu";
import styled from "styled-components";

export const ButtonContainer = styled.div``;

export const MenuIcon = styled(Menu)`
  color: ${(props) => props.theme.usermenu.selected};
`;

export const ButtonObject = styled.button`
  background: ${(props) => props.theme.usermenu.highlight}AA;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.color.stroke};
  left: 10px;
  top: 10px;
  padding: 5px 10px;
  color: ${(props) => props.theme.color.buttons.text};
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  z-index: 4;
  transition: 200ms ease;
  margin-bottom: 10px;

  :hover {
    background: ${(props) => props.theme.color.buttons.hover};
  }
`;
