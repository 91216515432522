import { GroupAdd } from "@styled-icons/material/GroupAdd";
import { Person } from "@styled-icons/material/Person";
import { PersonSearch } from "@styled-icons/material/PersonSearch";
import styled from "styled-components";

export const PersonIcon = styled(Person)`
  width: 2rem;
  max-width: 25px;
`;

export const PersonAddIcon = styled(PersonSearch)`
  width: 2rem;
  max-width: 25px;
`;

export const GroupAddIcon = styled(GroupAdd)`
  width: 2rem;
  max-width: 25px;
`;

export const AsideContainer = styled.aside`
  width: 100%;
  height: 8%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.text};
  justify-content: flex-end;
  background: ${(props) => props.theme.color.primary};
  margin-top: -5rem;
`;

export const UnorderedList = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.color.primary};
`;

export const ListLink = styled.div`
  width: 100%;
  max-width: fit-content;
  padding: 0.5rem 1rem;
  background: ${(props) => props.theme.color.background};
  display: flex;
  flex-direction: row;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  font-size: 1.5rem;
  border: 1px solid ${(props) => props.theme.color.stroke};
  border-bottom: none;
  @media (max-width: 415px) {
    font-size: 1.2rem;
  }
  .fitContent {
    background: red;
  }

  svg {
    margin-right: 0.1rem;
  }
  :first-child {
    cursor: default;
    border-radius: 0px 10px 0px 0px;
    border-left: none;
  }

  :not(.Active) {
    background: ${(props) => props.theme.color.primary};
    width: 100%;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.color.stroke};
    :hover {
      cursor: pointer;
    }
    :last-child {
      max-width: 100%;
      cursor: default;
      @media (max-width: 375px) {
        display: none;
      }
    }
  }
`;
