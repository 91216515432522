import { Form } from "@unform/core";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../atoms/Button";
import FilterInput from "../../atoms/FilterInput";
import { SendButton, SendIcon, TableFilterContainer } from "./styles";

function TableFilter({ setQuery }) {
  const [toggleFilter, setToggleFilter] = useState(false);

  const formRef = useRef(null);

  const handle_toggle_filter = () => {
    setToggleFilter(!toggleFilter);
  };

  const handle_submit = ({ query }, { reset }) => {
    setQuery(query);
    reset();
  };

  const submit_button = () => {
    formRef.current.submitForm();
  };

  useEffect(() => {
    document
      .getElementById("enter")
      .addEventListener("keypress", function (event) {
        if (event.keyCode === 13) {
          formRef.current.submitForm();
        }
      });
  }, []);

  return (
    <TableFilterContainer>
      <Button
        width="150px"
        margin="0 .5rem 0 .5rem"
        onClick={handle_toggle_filter}
      >
        Filtro
      </Button>
      <Form ref={formRef} onSubmit={handle_submit}>
        <FilterInput
          id="enter"
          style={{
            display: !!toggleFilter ? "inline-block" : "none",
          }}
          name="query"
          type="text"
          placeholder="Aperte Enter para aplicar"
          required
        />
        <SendButton
          onClick={submit_button}
          style={{ display: !!toggleFilter ? "inline-block" : "none" }}
        >
          <SendIcon />
        </SendButton>
      </Form>
    </TableFilterContainer>
  );
}

export default TableFilter;
