import React, { useContext, useState } from "react";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import EditUserInformation from "../../organism/EditUserInformation";
import UserInformation from "../../organism/UserInformation";
import { ProfileContainer } from "./styles";

function ProfileSettings() {
  const [editMode, setEditMode] = useState(false);
  const { user } = useContext(GeneralContext);

  return (
    <ProfileContainer>
      {!!editMode ? (
        <EditUserInformation user={user} editMode={setEditMode} />
      ) : (
        <UserInformation user={user} editMode={setEditMode} />
      )}
    </ProfileContainer>
  );
}

export default ProfileSettings;
