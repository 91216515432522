import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TableObject = styled.table`
  width: 90%;
  min-width: 100px;
  border-collapse: collapse;
  background: ${(props) => props.theme.color.primary};
  box-shadow: 0 3px 5px #0000001d;
  @media (max-width: 360px) {
    width: 81%;
  }
`;

export const TableBody = styled.tbody`
  tr:nth-child(even) {
    background: ${(props) => props.theme.color.background}FF;
  }
`;
export const TableHeader = styled.thead`
  th:nth-child(1) {
    border-top-left-radius: 5px;
  }
  th:nth-last-child(1) {
    border-top-right-radius: 5px;
  }
`;
export const TableRow = styled.tr``;
export const TableData = styled.td`
  padding: 1rem;
  font-size: 1.2rem;
  text-align: center;
  width: 10%;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    content-align: center;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;
export const TableHead = styled.th`
  text-align: left;
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
  color: #fafbfc;
  background: ${(props) => props.theme.color.secondary};
`;

export const TableSettings = styled.div`
  width: 90%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TableSettingsActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const TableDataContentHolder = styled.div`
  display: flex;
  justify-content: center;
`;
