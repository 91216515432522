import { LightbulbFill } from "@styled-icons/bootstrap/LightbulbFill";
import { Menu, MenuButton, MenuItem } from "reakit/Menu";
import styled from "styled-components";

export const LightIcon = styled(LightbulbFill)`
  color: ${(props) => props.theme.usermenu.icon};
  width: 1.5em;
  margin-right: 1em;
  margin-left: 0.5em;
`;

export const UserMenuItem = styled(MenuItem)`
  background: rgba(1, 1, 1, 0);
  border: none;
  color: ${(props) => props.theme.usermenu.text};
  width: 12vw;
  min-width: 190px;
  font-size: 15px;
  display: flex;
  font-family: "roboto";
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  height: 40%;
  &:hover {
    background: ${(props) => props.theme.usermenu.hover};
    border-radius: 5px;
  }
`;

export const UserMenu = styled(Menu)`
  background: ${(props) => props.theme.usermenu.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.usermenu.stroke};
  width: 13vw;
  min-width: 200px;
  min-height: 100px;
  height: 5vw;
  margin-bottom: 2rem;
  border-radius: 10px;
  z-index: 5;
  padding: 0;
`;

export const UserMenuButton = styled(MenuButton)`
  background: rgba(1, 2, 3, 0);
  border: none;
  align-self: center;
`;

export const PhotoHolder = styled.div`
  min-width: 30px;
  min-height: 30px;
  width: 2rem;
  height: 2rem;
  border: 1px solid black;
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const UserHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.div`
  display: none;
  white-space: nowrap;
  font-size: 1.5rem;
  margin-left: 10px;
  color: ${(props) => props.theme.color.text};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
`;
