import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *, *:before, *:after{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    input:focus, select:focus{
    }

    html, body, #root{
        width: 100%;
        height: 100%;
        font-size: 10px;
        font-family: 'Roboto', arial;
    }
`;
