import React, { useState } from "react";
import AddUser from "../../components/organism/AddUser";
import Header from "../../components/atoms/Header";
import ProfileSettings from "../../components/template/ProfileSettings";
import SettingsAside from "../../components/organism/SettingsAside";
import ManageUser from "../../components/organism/UserList";
import { SettingsContainer,SettingsContent } from "./styles";

function Report() {
  const [page, setPage] = useState("profile");

  const Content = () => {
    if (page === "profile") return <ProfileSettings />;
    if (page === "addUser") return <AddUser />;
    if (page === "manageUser") return <ManageUser />;
  };

  return (
    <SettingsContainer>
      <Header>
        <strong>DLP</strong>&nbsp;|&nbsp;Configurações
      </Header>
      <SettingsContent>
      <SettingsAside setPage={setPage} />
      <Content />
      </SettingsContent>
    </SettingsContainer>
  );
}

export default Report;
