import { useField } from "@unform/core";
import React, { useEffect, useRef } from "react";
import { InputContainer } from "./styles";

export default function LoginInput({ name, width, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <InputContainer>
      <input
        style={{ width }}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </InputContainer>
  );
}
