import styled from "styled-components";

export const ButtonContainer = styled.div``;

export const ButtonObject = styled.button`
  background: ${(props) => props.theme.color.buttons.background};
  border-radius: 10px;
  height: 40px;
  border: none;
  padding: 5px 10px;
  color: ${(props) => props.theme.color.buttons.text};
  font-size: 1.5em;
  cursor: pointer;
  position: relative;
  z-index: 3;
  transition: 200ms ease;
  margin-bottom: 10px;

  :hover {
    background: ${(props) => props.theme.color.buttons.hover};
  }
`;
