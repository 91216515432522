import React from "react";
import AuthProvider from "./contexts/AuthContext/AuthContextProvider";
import ThemeContextProvider from "./contexts/ThemeContext/ThemeContextProvider";
import Routes from "./routes";

const App = () => {
  return (
    <AuthProvider>
      <ThemeContextProvider>
        <Routes />
      </ThemeContextProvider>
    </AuthProvider>
  );
};

export default App;
