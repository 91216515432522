import styled from "styled-components";

export const ProfileContainer = styled.div`
  width: 100%;
  padding: 5px;
`;

export const ProfileInformation = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.background};
  padding: 10px 20px;
  border-radius: 5px;

  form {
    display: flex;
    font-size: 1.4rem;
  }
`;

export const InformationContainer = styled.div`
  width: 100%;
  color: ${(props) => props.theme.color.text};
`;

export const InformationTitle = styled.h2`
  color: ${(props) => props.theme.color.text}1d;
  font-size: inherit;
  margin-bottom: 0.5rem;
`;

export const InformationSection = styled.div`
  margin: 2rem 0;
`;

export const TableData = styled.td`
  padding: 1rem;
  font-size: 1.2rem;
  span {
    display: inline-block;
    width: 100%;
    white-space: wrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
`;

export const TableRow = styled.tr``;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;
