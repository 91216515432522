import DeleteIcon from "@material-ui/icons/Delete";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import api from "../../../services/api";
import Card from "../../atoms/Card";
import Table from "../../organism/ReportTable";
import Switch from "../../atoms/Switch";
import RoundedButton from "../../atoms/TableRoundedButton";
import {
  ActionsContainer,
  InformationContainer,
  ProfileContainer,
  ProfileInformation,
  TableData,
  TableRow,
} from "./styles";
function UserList() {
  const [pageTokens, setPageTokens] = useState({ 1: undefined });
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [sendReport, setSendReport] = useState(false);

  const { user } = useContext(GeneralContext);

  const { setLoading } = useContext(GeneralContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    api(
      `user/list?${pageTokens[page] !== undefined ? "pageToken=" : ""}${
        pageTokens[page]
      }`,
      {
        method: "GET",
      }
    ).then(async (res) => {
      const data = await res.json();
      if ((res.status >= 200 && res.status < 300) || sendReport === true) {
        setRows(data.response.users);
        setTotalUsers(data.response.pages);
        const key = page + 1;
        if (pageTokens[key] === undefined) {
          let newData = { ...pageTokens };
          newData[key] = data.response.pageToken;
          setPageTokens(newData);
        }
      }
      setSendReport(false);
      setLoading(false);
    });
  }, [page, pageTokens, sendReport, setLoading]);

  async function toggleMail(uid, email, sendEmail) {
    sendEmail = !sendEmail;
    api("setSendEmail", {
      method: "PUT",
      body: JSON.stringify({ uid, email, sendEmail }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        enqueueSnackbar("Alteração no recebimento alterado com sucesso!", {
          variant: "success",
        });
        setLoading(false);
        setSendReport(true);
      } else {
        enqueueSnackbar(data.response, { variant: "error" });
        setLoading(false);
      }
    });
  }

  const handle_delete = async (uid) => {
    setLoading(true);
    api("user", {
      method: "DELETE",
      body: JSON.stringify({ uid }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        enqueueSnackbar("Usuário removido com sucesso.", {
          variant: "success",
        });
        setLoading(false);
      } else {
        enqueueSnackbar(data.response, { variant: "error" });
        setLoading(false);
      }
    });
  };

  const TableRows = () => {
    return (
      <>
        {rows.length > 0
          ? rows.map((item, index) => (
              <TableRow key={index}>
                <TableData>{index}</TableData>
                <TableData title={item.uid}>
                  <span>{item.uid}</span>
                </TableData>
                <TableData title={item.displayName}>
                  <span>{item.displayName}</span>
                </TableData>
                <TableData title={item.email}>
                  <span>{item.email}</span>
                </TableData>
                <TableData title={item.role}>
                  <span>{item.role}</span>
                </TableData>
                <TableData>
                  <ActionsContainer>
                    <RoundedButton
                      disabled={
                        user.role === "admin" && item.role === "Proprietário"
                      }
                      onClick={() => handle_delete(item.uid)}
                    >
                      <DeleteIcon />
                    </RoundedButton>
                  </ActionsContainer>
                </TableData>
                <TableData>
                  <Switch
                    disabled={
                      user.role === "admin" && item.role === "Proprietário"
                    }
                    onChange={() => {
                      toggleMail(item.uid, item.email, item.sendEmail);
                    }}
                    checked={item.sendEmail}
                  />
                </TableData>
              </TableRow>
            ))
          : null}
      </>
    );
  };

  return (
    <ProfileContainer>
      <ProfileInformation>
        <InformationContainer>
          <Card width={"100%"} minWidth={"250px"} overflow={"scroll"}>
            <Table
              page={page}
              setPage={setPage}
              results={totalUsers}
              tableHeader={[
                "Índice",
                "ID",
                "Nome",
                "Email",
                "Cargo",
                "Ações",
                "Email",
              ]}
              TableRows={TableRows}
            />
          </Card>
        </InformationContainer>
      </ProfileInformation>
    </ProfileContainer>
  );
}

export default UserList;
